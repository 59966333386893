export function q(selector: string, parent?: HTMLElement | null): HTMLElement {
    if (parent == null)
        parent = document.body;
    return parent.querySelector(selector)!
}

export function qa(selector: string, parent?: HTMLElement | null): HTMLElement[] {
    if (parent == null)
        parent = document.body;
    return [...parent.querySelectorAll<HTMLElement>(selector)];
}

export function qid(id: string): HTMLElement {
    return document.getElementById(id)!;
}

export function htmlToElement(html: string): Node {
    let temp = document.createElement("template");
    html = html.trim();
    temp.innerHTML = html;
    return temp.content.firstChild!;
}

export function attrStartsWith(attr: string, selector?: string | null, parent?: HTMLElement): HTMLElement[] {
    if (parent == null)
        parent = document.body;

    let sel: string = selector ?? "*";

    var els = [...parent.querySelectorAll<HTMLElement>(sel)];
    return els
        .filter(ele => [...ele.attributes]
            .filter(({ name }) => name.startsWith(attr))
            .length > 0
        )
}

export function parent(parentSelector: string, child: HTMLElement) {
    var parents: HTMLElement[] = [];
    var p = child.parentElement!;
    while (!p.matches(parentSelector)) {
        var o = p;
        p = o.parentElement!;
    }
    parents.push(p);
    return parents[0];
}