import { throttle } from 'throttle-typescript';

export function classyHit(selector: string, targetElement: HTMLElement, classToggle: string, rewind: boolean = true, onVisible?: Function, onHidden?: Function): void {
    let target = document.querySelector<HTMLElement>(selector);
    if (target == null)
        throw new Error(`Selector ${selector} found no such object`);

    let watch = targetElement;
    if (watch == null)
        throw new Error("You must have an element to watch.");

    let watchTop = watch.getBoundingClientRect().top + window.scrollY,
        targetBottom = target.getBoundingClientRect().top + target.offsetHeight;

    watchForHit();
    window.addEventListener('scroll', throttle(watchForHit, 5));

    function watchForHit() {
        if (watchTop - window.scrollY < targetBottom)
            target?.classList.add(classToggle);
        else
            target?.classList.remove(classToggle);
    }
}