import { home } from "./pages/home";
import { about } from "./pages/about";
import { services } from "./pages/services";
import { contact } from "./pages/contact";
import { classyHit } from "./plugins/classyHit";
import { domAwait } from "./plugins/domAwait";
import { fitContent } from "./plugins/fitContent";
import { attrStartsWith, q, qa } from "./plugins/helpers";
import { setupModals, showModal } from "./plugins/modal";
import { rellaxify } from "./plugins/rellaxify";
import { setupAosChildren } from "./plugins/aosChildren";
import { ajaxityForms } from "./plugins/ajaxifyForm";
import FontFaceObserver from "fontfaceobserver";

declare var AOS: any;

let d = new domAwait("body");
d.onReady(() => { loaded(); });

let slugs: {} = {
    "home": home,
    "about": about,
    "services": services,
    "contact": contact
};

function loaded() {
    let menu = q("nav.main-menu"),
        firstVisibleHeader = [...document.querySelectorAll<HTMLElement>('h1,h2,h3')]
            .filter(x => x.offsetWidth !== 0 || x.offsetHeight !== 0)[0];

    q("[data-menu-toggle]").addEventListener("click", function () {
        menu.classList.toggle("open");
    });
    document.body.classList.add("loaded");

    qa("[data-rellax]").forEach(e => {
        rellaxify(e);
    });
    ajaxityForms();
    setupAosChildren();
    setupModals();
    setUpFancyLinks();
    //showModal("#topic-modal");
    //showModal("#proposal-modal");
    AOS.init({
        once: true
    });

    // Load the appropriate page
    let slug = document.body.getAttribute("data-slug") ?? "home";
    if (slug === "/")
        slug = "home";

    slugs[slug]();

    if (slug === "contact") {
        document.body.classList.add("nav-loaded");
        menu.classList.remove("loading");
        classyHit("nav.main-menu", firstVisibleHeader, "scrolled", true);
    } else if (slug !== "home") {
        document.body.classList.add("nav-loaded");
        menu.classList.remove("loading");
        menu.classList.add("scrolled");
    }
    try {

    } catch (e) { }

    var font = new FontFaceObserver('adobe-caslon-pro', {
        weight: 400
    });

    font.load().then(function () {
        fitHero();
    }, function () {
        console.log('Font is not available');
    });

    // Check for proposal iframe and set its height
    // THIS WILL NOT WORK CROSS-DOMAIN WITH WUFOO!
    window['proposalFormLoaded'] = function () {
        let frame = q("#proposal-frame") as HTMLIFrameElement;
        if (frame) {
            // here you can make the height, I delete it first, then I make it again
            frame.style.height = "";
            frame.style.height = frame.contentWindow!.document.body.scrollHeight + "px";
        }
    }

    function fitHero() {
        if (slug !== "contact")
            fitContent(".hero .container h1,.hero .container h2");
    }

    // Wraps ordered list item text in <span>s so that the fancy bullets can work.
    function setUpFancyLinks() {
        qa("ol").forEach(list => {
            qa("li", list).forEach(item => {
                if (q("span", item) === null) {
                    let text = item.textContent;
                    item.innerHTML = `<span>${text}</span>`;

                }
            });
        })

    }
}