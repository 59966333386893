import { cloneStyles } from "./cloneStyles";

export function fitContent(selector: string): void {
    let selectorList: string[] = selector.split(","),
        targets: HTMLElement[] = [],
        fudgeFactor = 20,
        factor = 10,
        maxWidth = 0;

    selectorList.forEach(s => {
        let target = document.querySelector<HTMLElement>(s);
        if (target === null) {
            //console.log(`Selector ${s} found no such object. Continuing…`);
            return;
        }
        targets.push(target!);
    });

    // Get the max width for all items
    targets.forEach(e => { calcFrame(e) });

    // … and assign it back to all of them
    targets.forEach(e => { e.style.width = `${maxWidth}px`; });

    // Add a throttled window listener to redo this.

    function calcFrame(el: HTMLElement) {
        // Create a shadow offscreen copy with the existing CSS styles and width
        let clone = el.cloneNode(true) as HTMLElement;
        //  cloneStyles(el, clone);
        Object.assign(clone.style, {
            "height": "auto",
            "visibility": "hidden",
            "position": "absolute",
            "bottom": 0
        });

        let elWidth = el.clientWidth,
            elHeight = el.clientHeight; // Note its height; that takes into account the current word wrap.

        // Set the starting width
        clone.style.width = `${elWidth}px`;
        el.after(clone);
        //document.body.append(clone);

        // Start decreasing the clone's width and check the height until we find the height's increased, meaning the wrapping increased.
        while (elWidth > 100) {
            elWidth -= factor;

            clone.style.width = `${elWidth}px`;
            let curHeight = clone.clientHeight;
            if (curHeight > elHeight) {
                elWidth += factor + fudgeFactor; // Fudge factor for custom fonts that may not have loaded yet.
                break;
            }
        }
        clone.remove();
        //clone.style.opacity = "1";

        if (elWidth > maxWidth)
            maxWidth = elWidth;
    }
}