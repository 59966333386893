import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export function about() {
    gsap.from(".we-connect-header", {
        scrollTrigger: {
            start: 'top 70%',
            end: 'bottom top',
            trigger: ".we-connect-header",
            toggleClass: 'active',
            once: true
        }
    });
}