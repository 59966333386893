import Rellax from "rellax";

var rellaxDefaults = {
    speed: -2,
    zindex: 0,
    center: true,
    percentage: 0.5,
};
export function rellaxify(el: HTMLElement) {
    let config = {};
    // Get all the data-rellax-* attributes
    [...el.attributes].filter(a => {
        return a.name.startsWith("data-rellax-");
    }).forEach(a => {
        config[a.name] = a.value
    });
    let newConfig = {
        ...rellaxDefaults,
        ...config
    };
    return new Rellax(el, newConfig);
}

/*
var dataPercentage = el.getAttribute( 'data-rellax-percentage' );
var dataSpeed = el.getAttribute( 'data-rellax-speed' );
var dataXsSpeed = el.getAttribute( 'data-rellax-xs-speed' );
var dataMobileSpeed = el.getAttribute( 'data-rellax-mobile-speed' );
var dataTabletSpeed = el.getAttribute( 'data-rellax-tablet-speed' );
var dataDesktopSpeed = el.getAttribute( 'data-rellax-desktop-speed' );
var dataVerticalSpeed = el.getAttribute('data-rellax-vertical-speed');
var dataHorizontalSpeed = el.getAttribute('data-rellax-horizontal-speed');
var dataVericalScrollAxis = el.getAttribute('data-rellax-vertical-scroll-axis');
var dataHorizontalScrollAxis = el.getAttribute('data-rellax-horizontal-scroll-axis');
var dataZindex = el.getAttribute( 'data-rellax-zindex' ) || 0;
var dataMin = el.getAttribute( 'data-rellax-min' );
var dataMax = el.getAttribute( 'data-rellax-max' );
var dataMinX = el.getAttribute('data-rellax-min-x');
var dataMaxX = el.getAttribute('data-rellax-max-x');
var dataMinY = el.getAttribute('data-rellax-min-y');
var dataMaxY = el.getAttribute('data-rellax-max-y');
*/