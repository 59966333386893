import { q, qa } from "./helpers";
import hyperform from "hyperform";

export function ajaxityForms() {
    qa("form").forEach(f => {
        let spinner = document.createElement("div");
        spinner.classList.add("spinner");
        f.appendChild(spinner);

        let errors = document.createElement("div");
        errors.classList.add("errors");
        f.firstChild?.before(errors);

        if (!f.hasAttribute("id"))
            f.setAttribute("id", `form-${makeid(8)}`);

        let idSelector = `#${f.getAttribute("id")}`;

        /* change Hyperform's renderer to write into the global error div */
        hyperform.setRenderer('attachWarning', function (warning, element) {
            let input: HTMLInputElement = element as HTMLInputElement;
            if (input.labels && input.labels.length) {
                /* add the label's text, if we have one */
                warning.insertAdjacentText('afterbegin', input.labels[0].textContent!);
            }
            input.before(warning);
        });
        hyperform(f, {
            classes: {
                warning: 'warning',
                invalid: 'invalid'
            }
        });

        f.onsubmit = function (event) {
            event.stopPropagation();
            event.preventDefault();

            f.classList.add("submitting");
            let data = f as HTMLFormElement,
                action = data.getAttribute('action') || window.location.href;

            errors.textContent = "";
            const config = {

            }
            fetch(action, {
                method: "POST",
                body: new FormData(data),
                headers: {
                    "X-Requested-With": "fetch"
                }
            }).then(res => res.json())
                .then(function (json) {
                    f.classList.remove("submitting");

                    if (!json) {
                        errors.textContent = "We're sorry, but something went wrong submitting this form."
                        return;
                    }

                    if (json.s && json.s === "s") {
                        f.classList.add("submitted");
                        return;
                    } else {
                        errors.insertAdjacentHTML('beforeend', `${json.m}<br/>`);
                        if (json.f) {
                            json.f.forEach(m => {
                                errors.insertAdjacentHTML('beforeend', `${m}<br/>`);
                            });
                        }
                        errors.insertAdjacentHTML('beforeend', `</span>`);
                        return;
                    }
                });

            event.preventDefault();
        }
    });

    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}