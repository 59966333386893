export function domAwait(selector) {
    document.addEventListener("DOMContentLoaded", () => {
        this.element = document.querySelector(selector);
        if (typeof this.callback === 'function')
            this.callback();
    });
};

domAwait.prototype.onReady = function (callback) {
    this.callback = callback;
};