import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { classyHit } from "../plugins/classyHit";
import { q, qa, parent } from "../plugins/helpers";

gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, EaselPlugin, ExpoScaleEase, RoughEase, SlowMo);

export function home() {
    animateNav();
    //setTimeout(animatePersonTestimonials, 1000);
    animatePersonTestimonials();
    animateCompanyTestimonials();
    animateClientLogos();
}

function animateNav() {
    let menu = q("nav.main-menu"),
        firstVisibleHeader = [...document.querySelectorAll<HTMLElement>('h1,h2,h3')]
            .filter(x => x.offsetWidth !== 0 || x.offsetHeight !== 0)[0];
    function navLoaded() {
        document.body.classList.add("nav-loaded");
        menu.classList.remove("loading");
        classyHit("nav.main-menu", firstVisibleHeader, "scrolled", true);
    }

    menu.addEventListener("animationend", navLoaded);
    menu.addEventListener("transitionend", navLoaded);
    setTimeout(navLoaded, 4000);
}

function animatePersonTestimonials() {
    // Calculate our step size.
    let speechBubble = q(".speech-bubble-arrow"),
        stepHeight = speechBubble.getBoundingClientRect().height,
        frames: number[] = [0, 8, 14],
        rate = (1 / 24) * 1000,
        testimonials: { [name: string]: string } = {},
        currentPosition = 0,
        tallestTestimonialHeight = 0,
        testimonialContainer: HTMLElement = q(".people-testimonials blockquote"),
        text: string = "",
        autoAdvanceMs = 0, //4000,
        advanceInterval: number;

    // Get our person testimonials

    qa(".people-testimonials blockquote[data-testimonial-by]").forEach(e => {
        let author = e.getAttribute("data-testimonial-by");
        if (e.offsetHeight > tallestTestimonialHeight)
            tallestTestimonialHeight = e.offsetHeight;

        let p = q("div[data-testimonial-html]", e);
        e.setAttribute("data-testimonial", p.innerHTML);
        // p.style.height = `${p.offsetHeight}px`;
        // @ts-ignore: Object is possibly 'null'.
        testimonials[author] = p.innerHTML;
        p.innerHTML = "";
    });
    qa(".people-testimonials blockquote").forEach((e, i) => {
        if (i == 0) {
            testimonialContainer = q("div[data-testimonial-html]", e);
            e.style.height = `${tallestTestimonialHeight}px`;
        }
        else
            e.remove();
    });
    testimonialContainer.innerHTML = testimonials[Object.keys(testimonials)[0]];

    q(".people-testimonials ul").addEventListener("click", (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let target = (<HTMLElement>e.target);

        while (target.tagName.toLowerCase() != "a")
            target = target.parentElement!;

        let person = target.getAttribute("data-testimonial")!;
        clearInterval(advanceInterval);
        showPerson(person);
    });

    // Auto-advance
    if (autoAdvanceMs > 0)
        advanceInterval = setInterval(nextTestimonial, autoAdvanceMs);

    function nextTestimonial() {
        let nextPosition = currentPosition;
        nextPosition++;
        if (nextPosition >= Object.keys(testimonials).length)
            nextPosition = 0;

        showPerson(Object.keys(testimonials)[nextPosition]);
    }

    function showPerson(person: string) {
        let curActive = q(".people-testimonials ul li a.active")
        if (curActive)
            curActive.classList.remove("active");

        q(`.people-testimonials ul li a[data-testimonial="${person}"]`).classList.add("active");
        text = testimonials[person];
        let idx = Object.keys(testimonials).indexOf(person);
        gsap.to(".people-testimonials blockquote", {
            opacity: 0,
            duration: 0.15,
            onComplete: function () {
                testimonialContainer.innerHTML = text;
                gsap.to(".people-testimonials blockquote", {
                    opacity: 1,
                    duration: 0.15
                });
            }
        });

        navigateTo(idx);
    }

    function navigateTo(positionNumber) {
        // Get the current bgd top position
        let currentTop = +getComputedStyle(speechBubble).backgroundPosition.split(" ")[1].replace("px", "");

        // And target background top position
        let targetTop = -1 * (frames[positionNumber] * stepHeight);

        if (positionNumber > currentPosition)
            animateBubbleRight();
        else
            animateBubbleLeft();

        currentPosition = positionNumber;

        function animateBubbleRight() {
            setTimeout(function () {
                currentTop -= stepHeight;
                speechBubble.style.backgroundPositionY = `${currentTop}px`;
                if (currentTop > targetTop)
                    animateBubbleRight();
                else
                    speechBubble.style.backgroundPositionY = `${targetTop}px`;
            }, rate);
        }

        function animateBubbleLeft() {
            setTimeout(function () {
                currentTop += stepHeight;
                speechBubble.style.backgroundPositionY = `${currentTop}px`;
                if (currentTop < targetTop)
                    animateBubbleLeft();
                else
                    speechBubble.style.backgroundPositionY = `${targetTop}px`;
            }, rate);
        }
    }

    // Start it up!
    showPerson(q(".people-testimonials ul li a").getAttribute('data-testimonial')!);
}

function animateCompanyTestimonials() {
    let testimonials: { [name: string]: string } = {},
        tallestTestimonialHeight = 0,
        parentContainer: HTMLElement = q(".company-testimonials blockquote"),
        testimonialContainer: HTMLElement = q(".company-testimonials blockquote"),
        text: string = "",
        iconBgd = q(".company-testimonials .bgd .bgd-before"),
        autoAdvanceMs = 0, //4000,
        currentPosition = 0,
        advanceInterval: number;

    qa(".company-testimonials blockquote[data-testimonial-by]").forEach(e => {
        let author = e.getAttribute("data-testimonial-by");
        if (e.offsetHeight > tallestTestimonialHeight)
            tallestTestimonialHeight = e.offsetHeight;

        let p = q("p", e);
        e.setAttribute("data-testimonial", p.textContent!);
        p.style.height = `${p.offsetHeight}px`;
        // @ts-ignore: Object is possibly 'null'.
        testimonials[author] = p.textContent;
        p.textContent = "";
    });
    qa(".company-testimonials blockquote").forEach((e, i) => {
        if (i == 0) {
            testimonialContainer = q("p", e);
            e.style.height = `${tallestTestimonialHeight}px`;
        }
        else
            e.remove();
    });
    testimonialContainer.textContent = testimonials[Object.keys(testimonials)[0]];

    // Auto-advance
    if (autoAdvanceMs > 0)
        advanceInterval = setInterval(nextTestimonial, autoAdvanceMs);

    function nextTestimonial() {
        let nextPosition = currentPosition;
        nextPosition++;
        if (nextPosition >= Object.keys(testimonials).length)
            nextPosition = 0;

        showCompany(Object.keys(testimonials)[nextPosition]);
    }

    q(".company-testimonials ul").addEventListener("click", (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let target = (<HTMLElement>e.target);

        while (target.tagName.toLowerCase() != "a")
            target = target.parentElement!;

        let company = target.getAttribute("data-testimonial")!;
        clearInterval(advanceInterval);
        showCompany(company);
    });

    showCompany(q(".company-testimonials ul li a").getAttribute('data-testimonial')!);
    function showCompany(company: string) {
        let curActive = q(".company-testimonials ul li a.active");
        if (curActive)
            curActive.classList.remove("active");
        let target = q(`.company-testimonials ul li a[data-testimonial="${company}"]`);
        target.classList.add("active");

        text = testimonials[company];
        currentPosition = Object.keys(testimonials).indexOf(company);
        let icon = q("img", target).getAttribute("src")!,
            textTarget = getComputedStyle(parentContainer).right,
            iconTarget = getComputedStyle(iconBgd).right;

        gsap.to(parentContainer, {
            right: "100vw",
            duration: 0.5,
            ease: "power2.inOut",
            onComplete: function () {
                testimonialContainer.textContent = text;
                gsap.fromTo(parentContainer, {
                    right: "-100vw"
                },
                    {
                        right: textTarget,
                        duration: 0.25,
                        ease: "power2.inOut"
                    });
            }
        });
        gsap.to(iconBgd, {
            right: "100vw",
            duration: .65,
            ease: "power2.inOut",
            onComplete: function () {
                iconBgd.style.backgroundImage = `url(${icon})`;
                gsap.fromTo(iconBgd, {
                    right: "-100vw"
                },
                    {
                        right: iconTarget,
                        duration: 0.35,
                        ease: "power1.inOut"
                    });
            }
        });
    }
}

function animateClientLogos() {
    let logos: HTMLDivElement[] = [],
        clientBgd = q(".well.clients .bgd"),
        logoHeight = 0,
        logoWidth = 0,
        numColumns = 0,
        lastRowHidden = 0;

    qa("ul#client-logos li").forEach(l => {
        let div = document.createElement("div"),
            img = document.createElement("img");
        img.src = `/sitefiles/logos/${l.getAttribute("data-client")!}`;
        div.appendChild(img);
        logos.push(div);
    });
    q("ul#client-logos").remove();
    logos = logos
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    logos.forEach(l => {
        logos.push(l.cloneNode(true) as HTMLDivElement);
    });
    logos.forEach(l => {
        clientBgd.appendChild(l);
    });
    // Get the number of rows
    q("img", logos[0]).onload = function () {
        logoHeight = logos[0].offsetHeight;
        logoWidth = logos[0].offsetWidth;
        numColumns = Math.floor(clientBgd.offsetWidth / logoWidth);
        logos.forEach(l => {
            l.style.width = `${logoWidth}px`;
            l.style.height = `${logoHeight}px`;
        });
    };
    gsap.to(clientBgd, {
        top: "-100000%",
        duration: 30000,
        repeat: -1,
        ease: "none"
    });
    setInterval(moveHiddenLogos, 100);

    function moveHiddenLogos() {
        let bgdTop = +getComputedStyle(clientBgd).top.replace("px", "") * -1;
        let rowHidden = Math.floor(bgdTop / logoHeight);

        // Get the elements and re-append them.
        for (var i = lastRowHidden * numColumns; i < rowHidden * numColumns; i++) {
            let clone = logos[i].cloneNode(true) as HTMLDivElement;
            clientBgd.appendChild(clone);
            logos.push(clone);
            q("img", logos[i]).remove();
        }

        lastRowHidden = rowHidden;
    }
}