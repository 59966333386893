import { qa } from "./helpers";

export function setupAosChildren() {
    qa("[data-aos-child-config]").forEach(el => {
        // We have to convert quotes for JSON.parse to work
        let confStr = el.getAttribute("data-aos-child-config")!.replace(/'/g, "\"");

        // Parse the config
        let config = JSON.parse(confStr);
        if (!config.effect)
            config.effect = "fade-up";
        config[""] = config.effect;

        if (!config.delay)
            config.delay = "150";
        config.delay *= 1;

        let baseDelay = config.delay;
        baseDelay = config.delay * -1;
        // Get the children and give them the attributes
        qa(":scope > *", el).forEach(c => {
            Object.entries(config).forEach((entry) => {
                let [key, value] = entry;
                if (key === "effect")
                    return;
                if (key === "delay") {
                    baseDelay = baseDelay += config.delay;
                    value = baseDelay;
                }
                c.setAttribute(`data-aos${(key != "" ? "-" : "")}${key}`, value as string);
            });
        });
    });
}