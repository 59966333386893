import { attrStartsWith, q, qa } from "./helpers";
var triggers: HTMLElement[];
var modals: HTMLElement[];
var currentModal: HTMLElement | null;
var previousModal: HTMLElement | null;
var isShowing: boolean;

export function setupModals() {
    isShowing = false;

    // Get all modal triggers and modals
    triggers = qa(`[data-toggle="modal"][data-target]`),
        modals = qa(".modal"); // Doing these as variables so that I can potentially do delta refresh when instantiating new ones.

    triggers.forEach(t => {
        t.addEventListener("click", (e) => {
            // Tank the event.
            e.stopPropagation();
            e.preventDefault();
            showModal(t.getAttribute("data-target")!);
        });
    });

    modals.forEach(m => {
        if (m.hasAttribute("data-fade"))
            m.classList.add("fade");

        if (!m.classList.contains("hidden"))
            m.classList.add("hidden");

        m["show"] = () => show(m);
        m["hide"] = () => hide(m);
        m.addEventListener("click", (e) => {
            if (e.target != null && (e.target! as HTMLElement).hasAttribute("data-dismiss") && (e.target! as HTMLElement).getAttribute("data-dismiss") == "modal") {
                hide(m);
            }
        });
    });

    currentModal = null;
    previousModal = null;
}

export function showModal(selector: string) {
    var modal = document.querySelector<HTMLElement>(selector);
    modals.filter(m => {
        return m.isSameNode(modal)
    }).forEach(m => {
        m["show"]();
    });
}

export function hideModal(selector: string) {
    var modal = document.querySelector<HTMLElement>(selector);
    modals.filter(m => {
        return m.isSameNode(modal)
    }).forEach(m => {
        m["hide"]();
    });
}

function showPreviousModal() {
    if (previousModal) {
        var prev = previousModal!;
        previousModal = null;
        show(prev);
    }
}

function show(modal: HTMLElement) {
    isShowing = true;
    let evt = new CustomEvent("modal.show", { detail: modal }),
        firedShown: boolean = false;

    if (currentModal) {
        hide(currentModal);
    }

    currentModal = modal;
    document.body.classList.add("modal-open");
    modal.addEventListener("animationend", fireShown, false);
    modal.addEventListener("transitionend", fireShown, false);

    let backdrop = q(".modal-backdrop");
    if (!backdrop) {
        backdrop = document.createElement('div');
        backdrop.classList.add("modal-backdrop", "fade");
        document.body.appendChild(backdrop);
    }
    if (!modal.hasAttribute("data-modal-backdrop") || modal.getAttribute("data-modal-backdrop") !== "static")
        backdrop.addEventListener("click", (e) => {
            hide(modal);
        }); // handle static backdrop on chained modals

    modal.dispatchEvent(evt);
    modal.classList.remove("hidden");

    let servicesBtn = q("#topic-modal #services-button"),
        proposalBtn = q("#topic-modal #proposal-button");

    //  proposalBtn.style.width = `${servicesBtn.offsetWidth}px`;

    setTimeout(function () {
        backdrop.classList.add("show");
        modal.classList.add("show");
        modal.ariaHidden = "false";
    }, 1);

    function fireShown() {
        if (!firedShown) {
            let shown = new CustomEvent("modal.shown", { detail: modal });
            modal.dispatchEvent(shown);
            modal.removeEventListener("animationend", fireShown, false);
            modal.removeEventListener("transitionend", fireShown, false);
            isShowing = false;
        }
    }
}

function hide(modal: HTMLElement) {
    let evt = new CustomEvent("modal.hide", { detail: modal }),
        firedHidden: boolean = false;

    currentModal = null;

    modal.addEventListener("animationend", fireHidden);
    modal.addEventListener("transitionend", fireHidden);

    modal.dispatchEvent(evt);
    modal.classList.remove("show");
    modal.ariaHidden = "true";

    let backdrop = q(".modal-backdrop");
    if (!isShowing) {
        if (backdrop) {
            backdrop.classList.remove("show");
            setTimeout(function () {
                backdrop.remove();
                document.body.classList.remove("modal-open");
            }, 150);
        }
    }
    function fireHidden() {
        if (!firedHidden) {
            let hidden = new CustomEvent("modal.hidden", { detail: modal });
            modal.classList.add("hidden");
            modal.dispatchEvent(hidden);
            modal.removeEventListener("animationend", fireHidden, false);
            modal.removeEventListener("transitionend", fireHidden, false);
            if (!isShowing) {
                backdrop.remove();
                document.body.classList.remove("modal-open");
            }
        }
    }
}
